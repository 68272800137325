<template>
  <b-container v-if="!isLoading" fluid>
    <AnalyticsHeader
      headerTitle="ORDER DASHBOARD"
      @onDateChange="updateDate"
      @extractAnalysis="extractAnalysis"
    />
    <AnalyticsIcons :iconData="iconData" :itemsPerRow="4" />
    <!-- <Chart
      v-show="filterBy === 'monthly'"
      :selectedFilterBy="filterBy"
      :chartOptions="chartOptionsMonthly"
      @filterGraphBy="filterGraphBy"
    />
    <Chart
      v-show="filterBy === 'daily'"
      :selectedFilterBy="filterBy"
      :chartOptions="chartOptionsDaily"
      @filterGraphBy="filterGraphBy"
    /> -->
  </b-container>
</template>

<script>
import AnalyticsHeader from "./components/AnalyticsHeader.vue";
import AnalyticsIcons from "./components/AnalyticsIcons.vue";
import Chart from "../../../components/Chart.vue";
import exportFromJSON from "export-from-json";

export default {
  components: {
    AnalyticsHeader,
    AnalyticsIcons,
    Chart,
  },

  async created() {
    this.getAnalyticsData();
    this.isLoading = false;
  },

  methods: {
    updateDate(date, type) {
      if (type === "FROM") {
        this.fromDate = date;
      } else {
        this.toDate = date;
      }
      this.getAnalyticsData();
    },

    getAnalyticsData() {
      this.$http
        .get(`analytics/orders/${this.fromDate}/${this.toDate}`)
        .then((response) => {
          if (response.data.status) {
            this.chartData = response.data.data;
            this.getCountData();

            //UPDATE MONTHLY CHART
            // this.chartOptionsMonthly = {
            //   ...this.chartOptionsMonthly,
            //   xAxisData: this.$helpers.getMonths(this.fromDate, this.toDate),
            // };

            // const { firstMonth, lastMonth } = this.$helpers.getFirstAndLastMonths(
            //   this.chartOptionsMonthly.xAxisData
            // );
            // this.chartOptionsMonthly.series.forEach((series, i) => {
            //   switch (i) {
            //     case 0:
            //       series.data = this.chartData.active.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 1:
            //       series.data = this.chartData.scheduled.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 2:
            //       series.data = this.chartData.delivered.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 3:
            //       series.data = this.chartData.cold_room.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     default:
            //       break;
            //   }
            // });

            //UPDATE DAILY CHART
            // this.chartOptionsDaily = {
            //   ...this.chartOptionsDaily,
            //   xAxisData: this.$helpers.getDays(this.fromDate, this.toDate),
            // };

            // const { firstDay, lastDay } = this.$helpers.getFirstAndLastDays(
            //   this.chartOptionsDaily.xAxisData
            // );

            // this.chartOptionsDaily.series.forEach((series, i) => {
            //   switch (i) {
            //     case 0:
            //       series.data = this.chartData.active.daily.slice(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 1:
            //       series.data = this.chartData.scheduled.daily.slice(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 2:
            //       series.data = this.chartData.delivered.daily.slice(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 3:
            //       series.data = this.chartData.cold_room.daily.slice(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     default:
            //       break;
            //   }
            // });
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    },

    getCountData() {
      this.iconData.forEach((item, i) => {
        switch (i) {
          case 0:
            item.value = this.chartData.active.count;
            break;
          case 1:
            item.value = this.chartData.scheduled.count;
            break;
          case 2:
            item.value = this.chartData.delivered.count;
            break;
          case 3:
            item.value = this.chartData.cold_room.count;
            break;
          case 4:
            item.value = this.chartData.current_orders.today_order;
            break;
          case 5:
            item.value = this.chartData.current_orders.month_order;
            break;
          case 6:
            item.value = this.chartData.current_orders.year_order;
            break;
          case 7:
            item.value = this.chartData.current_orders.total_order;
            break;
          default:
            break;
        }
      });
    },

    async extractAnalysis() {
      const response = await this.$http.get(
        `analytics_report/orders/${this.fromDate}/${this.toDate}`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `OrdersAnalytics_${this.fromDate}-${this.toDate}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    filterGraphBy(filter) {
      this.filterBy = filter;
    },
  },

  data() {
    return {
      isLoading: true,
      fromDate: new Date(
        Date.now() - 5 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-CA"),
      toDate: new Date().toLocaleDateString("en-CA"),
      filterBy: "daily",
      exportType: "CSV",
      chartData: {},
      iconData: [
        {
          variant: "dark",
          icon: "BoxIcon",
          value: 0,
          label: "Active Orders",
        },
        {
          variant: "orange",
          icon: "TagIcon",
          value: 0,
          label: "Scheduled Orders",
        },
        {
          variant: "success",
          icon: "ThumbsUpIcon",
          value: 0,
          label: "Delivered Orders",
        },
        {
          variant: "lighter-blue",
          icon: "CloudSnowIcon",
          value: 0,
          label: "Orders In Cold Room",
        },
        {
          variant: "purple",
          icon: "TruckIcon",
          value: 0,
          label: "Today's Orders",
        },
        {
          variant: "turquoise",
          icon: "ActivityIcon",
          value: 0,
          label: "This Month's Orders",
        },
        {
          variant: "brown",
          icon: "ClipboardIcon",
          value: 0,
          label: "This Year's Orders",
        },
        {
          variant: "primary",
          icon: "ShoppingCartIcon",
          value: 0,
          label: "Overall Orders",
        },
      ],
      chartOptionsDaily: {
        legend: {
          left: "left",
          data: [
            "Active Orders",
            "Scheduled Orders",
            "Delivered Orders",
            "Orders In Cold Room",
            // "Today's Orders",
            // "This Month's Orders",
            // "This Year's Orders",
            // "Overall Orders",
          ],
        },
        xAxisData: [],
        series: [
          {
            name: "Active Orders",
            type: "line",
            data: [],
            itemStyle: { color: "#4b4b4b" },
          },
          {
            name: "Scheduled Orders",
            type: "line",
            data: [],
            itemStyle: { color: "#e8833a" },
          },
          {
            name: "Delivered Orders",
            type: "line",
            data: [],
            itemStyle: { color: "#2FE966" },
          },
          {
            name: "Orders In Cold Room",
            type: "line",
            data: [],
            itemStyle: { color: "#d5e7f7" },
          },
          // {
          //   name: "Today's Orders",
          //   type: "line",
          //   data: [],
          //   itemStyle: { color: "#bd34d1" },
          // },
          // {
          //   name: "This Month's Orders",
          //   type: "line",
          //   data: [],
          //   itemStyle: { color: "#1aae9f" },
          // },
          // {
          //   name: "This Year's Orders",
          //   type: "line",
          //   data: [],
          //   itemStyle: { color: "#700000" },
          // },
          // {
          //   name: "Overall Orders",
          //   type: "line",
          //   data: [],
          //   itemStyle: { color: "#db1d6c" },
          // },
        ],
      },
      chartOptionsMonthly: {
        legend: {
          left: "left",
          data: [
            "Active Orders",
            "Scheduled Orders",
            "Delivered Orders",
            "Orders In Cold Room",
            // "Today's Orders",
            // "This Month's Orders",
            // "This Year's Orders",
            // "Overall Orders",
          ],
        },
        xAxisData: [],
        series: [
          {
            name: "Active Orders",
            type: "line",
            data: [],
            itemStyle: { color: "#4b4b4b" },
          },
          {
            name: "Scheduled Orders",
            type: "line",
            data: [],
            itemStyle: { color: "#e8833a" },
          },
          {
            name: "Delivered Orders",
            type: "line",
            data: [],
            itemStyle: { color: "#2FE966" },
          },
          {
            name: "Orders In Cold Room",
            type: "line",
            data: [],
            itemStyle: { color: "#d5e7f7" },
          },
          // {
          //   name: "Today's Orders",
          //   type: "line",
          //   data: [],
          //   itemStyle: { color: "#bd34d1" },
          // },
          // {
          //   name: "This Month's Orders",
          //   type: "line",
          //   data: [],
          //   itemStyle: { color: "#1aae9f" },
          // },
          // {
          //   name: "This Year's Orders",
          //   type: "line",
          //   data: [],
          //   itemStyle: { color: "#700000" },
          // },
          // {
          //   name: "Overall Orders",
          //   type: "line",
          //   data: [],
          //   itemStyle: { color: "#db1d6c" },
          // },
        ],
      },
    };
  },
};
</script>

<style></style>
